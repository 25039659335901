@charset "UTF-8";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@font-face {
  font-family: "Montserrat";
  src: url("../font-family/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../font-family/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Serif";
  src: url("../font-family/PT_Serif/PTSerif-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/**
   COULEURS
*/
/**
   Responsive
 */
.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px;
}

.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-button:active {
  outline: none;
}

.mdc-button:hover {
  cursor: pointer;
}

.mdc-button:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}

.mdc-button.mdc-button--dense {
  border-radius: 4px;
}

.mdc-button:not(:disabled) {
  background-color: transparent;
}

.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}

[dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

.mdc-button:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

[dir="rtl"] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir="rtl"] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

[dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
.mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir="rtl"] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

[dir="rtl"] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
.mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir="rtl"] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}

.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #6200ee;
}

@supports not (-ms-ime-align: auto) {
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #6200ee);
  }
}

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-button--raised:hover, .mdc-button--raised:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--raised:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-button--raised:disabled {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
  padding: 0 15px 0 15px;
  border-width: 1px;
}

.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.37);
}

.mdc-button--outlined:not(:disabled) {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden;
}

.mdc-ripple-surface--test-edge-var-bug::before {
  border: var(--mdc-ripple-surface-test-edge-var);
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-button::before, .mdc-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button::before, .mdc-button::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button::before, .mdc-button::after {
  background-color: #6200ee;
}

@supports not (-ms-ime-align: auto) {
  .mdc-button::before, .mdc-button::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #6200ee);
  }
}

.mdc-button:hover::before {
  opacity: 0.04;
}

.mdc-button.mdc-ripple-upgraded--background-focused::before, .mdc-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  background-color: #fff;
}

@supports not (-ms-ime-align: auto) {
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}

.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08;
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1, h2 {
  font-family: "PT Serif", "Times New Roman", serif;
}

h1 {
  font-size: 2.5em;
  color: #573D1C;
  text-transform: uppercase;
  font-weight: normal;
}

h2 {
  font-size: 2em;
  color: #573D1C;
  font-weight: normal;
}

h3 {
  font-size: 1.3em;
  font-family: 'Montserrat', sans-serif;
  color: #666;
  margin-bottom: 0;
  font-weight: 400;
}

p,
ul > li {
  font-family: "Open Sans", sans-serif;
}

p {
  line-height: 1.5em;
  text-align: justify;
}

main {
  min-height: 600px;
}

.flex-row, .header_logo, .header_nav-bar .nav-bar_items, .header_basket .count, .footer_nav-bar .footer_logo, .footer_nav-bar .nav-bar_items, .footer_nav-bar .nav-bar_payment-methods, .categories, .header-banniere_content {
  display: flex;
  flex-direction: row;
}

.flex-column, .categories .category_details, .sommaire {
  display: flex;
  flex-direction: column;
}

.flex-center-center, .header_logo, .header_basket .count, .footer_nav-bar .footer_logo, .footer_nav-bar .nav-bar_items, .footer_nav-bar .nav-bar_payment-methods, .categories .category, .header-banniere_content, .sommaire {
  justify-content: center;
  align-items: center;
}

.text-center, .header-banniere_content p {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-justify h1, .text-justify h2, .text-justify h3 {
  text-align: initial;
}

.container_center {
  width: 90%;
  max-width: 850px;
  margin: 0 auto 3em;
}

.separateur {
  display: block;
  width: 100%;
  border-bottom: solid 1px lightgray;
}

.separateur.separateur__vertical {
  height: 200px;
  width: 0;
  border-left: solid 1px lightgray;
}

.main__header-padding {
  padding-top: 120px;
}

@media (max-width: 767px) {
  .main__header-padding {
    padding-top: 72px;
  }
}

/**
   Background center cover
 */
.container-background, .header-banniere, .categories .category {
  position: relative;
  overflow: hidden;
}

.container-background img, .header-banniere img, .categories .category img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
  object-position: center;
}

/**
   Header avec le fond et la catch phrase
 */
.header-banniere_content {
  font-size: 2.5em;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 100px 0;
  margin: 0;
  color: #fff;
}

/**
   Loader Spinner
 */
.spinner-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.spinner-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #CC6600;
  border-color: #CC6600 transparent #CC6600 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
   Style du bouton
 */
.mdc-button:not(:disabled) {
  color: #CC6600;
}

.mdc-button--outlined:not(:disabled) {
  border-color: #CC6600;
}

.mdc-button::before, .mdc-button::after {
  background-color: #CC6600;
}

.mdc-button--raised {
  background-color: #CC6600 !important;
  color: white !important;
}

/**
   Bandeau Alert Cookies
 */
.alert-cookies {
  z-index: 10;
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: #573D1C;
  border-top: solid 2px #CC6600;
  padding: 15px 20px;
  box-sizing: border-box;
}

.alert-cookies p {
  flex: 4;
}

.alert-cookies_actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .alert-cookies {
    flex-direction: column;
  }
}

/**
   ToolTip
 */
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: calc(100% + 10px);
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  padding: 5px;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
  color: #fff;
  text-align: center;
  background-color: #78909C;
}

.tooltip .tooltiptext:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #78909C transparent;
}

.tooltip:hover .tooltiptext {
  animation: tooltip-animation ease-in-out 0.3s;
  visibility: visible;
  opacity: 1;
}

@keyframes tooltip-animation {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

/**
    HEADER
 */
header {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 2;
}

/**
   Logo Jollet dans le header
*/
.header_logo {
  height: 70px;
}

.header_logo svg {
  height: 40px;
}

.header_logo label {
  display: none;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 35px;
}

.header_logo label span {
  display: block;
  width: 25px;
  margin: 5px 0;
}

.header_logo label span:after {
  content: '';
  display: block;
  width: 25px;
  height: 2px;
  background-color: #573D1C;
}

/**
   Burger menu du mode téléphone
*/
#header_toggle {
  display: none;
}

#header_toggle:checked + .header_logo label span:nth-child(1):after {
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  position: absolute;
}

#header_toggle:checked + .header_logo label span:nth-child(2):after {
  visibility: hidden;
}

#header_toggle:checked + .header_logo label span:nth-child(3):after {
  transform: translateY(-50%) rotate(-45deg);
  top: 50%;
  position: absolute;
}

/**
   Menu de navigation
*/
.header_nav-bar {
  display: flex;
  justify-content: center;
  border-bottom: solid 2px #573D1C;
}

.header_nav-bar .nav-bar_items {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

.header_nav-bar .nav-bar_items li {
  min-width: 150px;
  text-align: center;
  text-transform: uppercase;
  font-family: "PT Serif", "Times New Roman", serif;
}

.header_nav-bar .nav-bar_items li a {
  color: #573D1C;
  font-size: 1.1em;
  background: linear-gradient(to top, #573D1C 0%, #573D1C 2px, transparent 2px) no-repeat;
  background-size: 0 100%;
  transition: background-size 0.3s;
}

.header_nav-bar .nav-bar_items li a.active, .header_nav-bar .nav-bar_items li a:hover {
  background-size: 100% 100%;
}

/**
   Panier de la boutique 
   (visible que dans la boutique)
*/
.header_basket {
  margin: 0 15px;
  position: absolute;
  right: 0;
  bottom: 5px;
}

.header_basket .count {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #d63030;
  border-radius: 50%;
  color: white;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-size: 0.8em;
}

.header_basket_empty .count {
  display: none;
}

.header_basket_up .count {
  animation: count-zoom 3s forwards 1;
}

@media (max-width: 767px) {
  .header_basket {
    display: none;
  }
}

@keyframes count-zoom {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
  30% {
    transform: scale(1);
  }
}

/**
   SPECIAL POUR LA HOME PAGE
 */
header.header__home-page {
  position: absolute;
  background-color: transparent;
  width: 600px;
  right: 0;
}

header.header__home-page .header_logo {
  display: none;
}

header.header__home-page .header_nav-bar {
  border: none;
}

header.header__home-page .header_nav-bar .nav-bar_items {
  margin: 30px 0 0;
}

header.header__home-page .header_nav-bar .nav-bar_items a {
  color: white;
  background: linear-gradient(to top, #fff 0%, #fff 2px, transparent 2px) no-repeat;
  background-size: 0 100%;
}

header.header__home-page .header_nav-bar .nav-bar_items a:hover {
  background-size: 100% 100%;
}

@media (max-width: 767px) {
  header.header__home-page {
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 2;
  }
  header.header__home-page .header_logo {
    display: flex;
  }
  header.header__home-page .header_nav-bar {
    border: none;
  }
  header.header__home-page .header_nav-bar .nav-bar_items {
    margin: 0 0 25px 0;
  }
  header.header__home-page .header_nav-bar .nav-bar_items a {
    color: #573D1C;
    background: linear-gradient(to top, #573D1C 0%, #573D1C 2px, transparent 2px) no-repeat;
    background-size: 0 100%;
    transition: background-size 0.3s;
  }
  header.header__home-page .header_nav-bar .nav-bar_items a.active, header.header__home-page .header_nav-bar .nav-bar_items a:hover {
    background-size: 100% 100%;
  }
}

/**
   RESPONSIVE
 */
@media (max-width: 767px) {
  .header_logo label {
    display: block;
  }
  .header_nav-bar {
    overflow: hidden;
    height: 0px;
    transition: height 0.3s ease-out;
  }
  .header_nav-bar .nav-bar_items {
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    margin-bottom: 10px;
  }
  .header_nav-bar .nav-bar_items li {
    margin: 10px 0;
  }
  #header_toggle:checked ~ .header_nav-bar {
    height: 215px;
  }
}

footer {
  background-color: #573D1C;
  padding: 20px 0;
  margin-top: 30px;
}

footer .text-center, footer .header-banniere_content p, .header-banniere_content footer p {
  color: white;
  font-family: Montserrat, "Open Sans", sans-serif;
}

.footer_nav-bar .footer_logo svg {
  height: 59px;
}

.footer_nav-bar .footer_logo svg * {
  fill: white !important;
}

.footer_nav-bar .nav-bar_items {
  list-style: none;
  margin: 25px 0;
  padding: 0;
}

.footer_nav-bar .nav-bar_items li {
  min-width: 150px;
  text-align: center;
  font-family: Montserrat, "Open Sans", sans-serif;
}

.footer_nav-bar .nav-bar_items li a {
  color: white;
}

.footer_nav-bar .nav-bar_items li a:hover {
  text-decoration: underline;
}

.footer_nav-bar .nav-bar_items li svg {
  max-width: 30px;
}

.footer_nav-bar .nav-bar_items li:nth-child(1) a:hover * {
  fill: cornflowerblue !important;
}

.footer_nav-bar .nav-bar_items li:nth-child(2) a:hover * {
  fill: beige !important;
}

.footer_nav-bar .nav-bar_payment-methods {
  margin-bottom: 10px;
}

.footer_nav-bar .nav-bar_payment-methods img {
  max-height: 50px;
  max-width: 120px;
}

@media (max-width: 767px) {
  .footer_nav-bar .nav-bar_payment-methods {
    margin-bottom: 20px;
  }
}

/**
   RESPONSIVE
 */
@media (max-width: 767px) {
  .footer_nav-bar .nav-bar_items:nth-child(2) {
    flex-direction: column;
  }
  .footer_nav-bar .nav-bar_items:nth-child(2) li {
    margin-bottom: 15px;
  }
}

.categories {
  flex-wrap: wrap;
  width: 100%;
}

.categories .category {
  position: relative;
  width: calc(100%/2 - 20px);
  height: 250px;
  color: white;
  margin: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 1;
}

@media (max-width: 767px) {
  .categories .category {
    width: 100%;
  }
}

.categories .category_details {
  position: absolute;
  top: calc(100% - 44px);
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.categories .category_details h3 {
  color: white;
  margin: 0 10px 10px 10px;
  font-size: 1.2em;
}

.categories .category_details p {
  color: transparent;
  width: 90%;
  height: 120px;
  margin: 0 10px;
  font-size: 0.92em;
}

.categories .category:hover > a {
  top: 0%;
}

.categories .category:hover > a h3 {
  margin: 10px;
}

.categories .category:hover > a p {
  animation-name: sommaire_item_animation;
  animation-duration: 0.8s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

/**
   Animations
 */
@keyframes sommaire_item_animation {
  from {
    color: transparent;
  }
  to {
    color: white;
  }
}

/**
   HOME PAGE
 */
/**
   Header avec le fond et la catch phrase
 */
.header-banniere_content {
  height: 750px;
  padding: 0;
}

.header-banniere_content svg {
  height: 200px;
}

.header-banniere_content svg * {
  fill: #fff !important;
}

.header-banniere_content p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4em;
  width: 90%;
  max-width: 700px;
}

/**
   Tuile animé d'hover
 */
.sommaire {
  background-color: #F2F2F2;
  padding: 25px 0;
}
