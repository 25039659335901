/**
    HEADER
 */

header {
   position: fixed;
   background-color: white;
   width: 100%;
   z-index: 2;
}

/**
   Logo Jollet dans le header
*/
.header_logo {
   @extend .flex-row;
   @extend .flex-center-center;
   height: 70px;

   svg {
      height: 40px;
   }
   label {
      display: none;
      cursor: pointer;
      padding: 5px;
      position: absolute;
      right: 35px;

      span {
         display: block;
         width: 25px;
         margin: 5px 0;

         &:after {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background-color: $color_primaire;
         }
      }
   }
}

/**
   Burger menu du mode téléphone
*/
#header_toggle {
   display: none;

   &:checked + .header_logo label {

      span:nth-child(1):after{
         transform: translateY(-50%) rotate(45deg);
         top: 50%;
         position: absolute;
      }
      span:nth-child(2):after {
         visibility: hidden;
      }
      span:nth-child(3):after {
         transform: translateY(-50%) rotate(-45deg);
         top: 50%;
         position: absolute;
      }
   }
}


/**
   Menu de navigation
*/
.header_nav-bar {
   display: flex;
   justify-content: center;
   border-bottom: solid 2px $color_primaire;

   .nav-bar_items {
      @extend .flex-row;
      list-style: none;
      margin: 0 0 25px 0;
      padding: 0;

      li {
         min-width: 150px;
         text-align: center;
         text-transform: uppercase;
         font-family: "PT Serif", "Times New Roman", serif;

         a {
            color: $color_primaire;
            font-size: 1.1em;

            background: linear-gradient(to top, $color_primaire 0%, $color_primaire 2px, transparent 2px) no-repeat;
            background-size: 0 100%;
            transition: background-size 0.3s;

            &.active,
            &:hover {
               background-size: 100% 100%;
            }
         }
      }
   }

}

/**
   Panier de la boutique 
   (visible que dans la boutique)
*/
.header_basket {
   margin: 0 15px;
   position: absolute;
   right: 0;
   bottom: 5px;

   .count {
      @extend .flex-row;
      @extend .flex-center-center;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: #d63030;
      border-radius: 50%;
      color: white;
      font-family: 'Montserrat', 'Open Sans', sans-serif;
      font-size: 0.8em;
   }

   &_empty .count {
         display: none;
   }

   &_up .count {
      animation: count-zoom 3s forwards 1;
   }

   @include media(mobileonly) {
      display: none;
   }

}

// animation du compteur de produit
@keyframes count-zoom {
   0% { transform: scale(1); }
   10% { transform: scale(1.5); }
   30% { transform: scale(1); }
}

/**
   SPECIAL POUR LA HOME PAGE
 */
header.header__home-page {
   position: absolute;
   background-color: transparent;
   width: 600px;
   right: 0;

   .header_logo {
      display: none;
   }

   .header_nav-bar {
      border: none;

      .nav-bar_items {
         margin: 30px 0 0;

         a {
            color: white;
            background: linear-gradient(to top, #fff 0%, #fff 2px, transparent 2px) no-repeat;
            background-size: 0 100%;

            &:hover {
               background-size: 100% 100%;
            }
         }
      }
   }
   @include media(mobileonly) {
      position: fixed;
      background-color: white;
      width: 100%;
      z-index: 2;

      .header_logo {
         display: flex;
      }

      .header_nav-bar {
         border: none;

         .nav-bar_items {
            margin: 0 0 25px 0;

            a {
               color: $color_primaire;
               background: linear-gradient(to top, $color_primaire 0%, $color_primaire 2px, transparent 2px) no-repeat;
               background-size: 0 100%;
               transition: background-size 0.3s;

               &.active,
               &:hover {
                  background-size: 100% 100%;
               }
            }
         }
      }
   }
}

/**
   RESPONSIVE
 */

$header_item-height: 23px;
$header_item-margin: 10px;
$header_items-count: 5;

@include media(mobileonly) {
   .header_logo {

      label {
         display: block;
      }
   }
   .header_nav-bar {
      overflow: hidden;
      height: 0px;
      transition: height 0.3s ease-out;

      .nav-bar_items {
         flex-direction: column;
         justify-content: space-around;
         margin: 0;
         margin-bottom: 10px;

         li {
            margin: 10px 0;
         }
      }
   }

   #header_toggle:checked ~ .header_nav-bar {
      height: ($header_item-height + $header_item-margin*2)*$header_items-count;
   }
}


