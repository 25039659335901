
/**
   Responsive
 */

@mixin media($point) {
   @if $point == mobileonly {
      @media (max-width: 767px) { @content; }
   }

   @else if $point == tablet {
      @media (max-width: 768px) { @content; }
   }

   @else if $point == laptop {
      @media (max-width: 992px) { @content; }
   }

   @else if $point == desktop {
      @media (max-width: 1200px) { @content; }
   }
   @else {
      @warn "Media mixin support: desktop, laptop, tablet, mobileonly"
   }
}