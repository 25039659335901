
@import "@material/button/mdc-button";

html,
body {
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
}

h1, h2 {
   font-family: "PT Serif", "Times New Roman", serif;
}

h1 {
   font-size: 2.5em;
   color: $color_primaire;
   text-transform: uppercase;
   font-weight: normal;
}

h2 {
   font-size: 2em;
   color: $color_primaire;
   font-weight: normal;
}

h3 {
   font-size: 1.3em;
   font-family: 'Montserrat', sans-serif;
   color: #666;
   margin-bottom: 0;
   font-weight: 400;
}

p,
ul > li {
   font-family: "Open Sans", sans-serif;
}

p {
   line-height: 1.5em;
   text-align: justify;
}

main {
   min-height: 600px;
}

.flex-row {
   display: flex;
   flex-direction: row;
}

.flex-column {
   display: flex;
   flex-direction: column;
}

.flex-center-center {
   justify-content: center;
   align-items: center;
}

.text-center {
   text-align: center;
}

.text-justify {
   text-align: justify;

   h1, h2, h3 {
      text-align: initial;
   }
}

.container_center {
   width: 90%;
   max-width: $width_max_content;
   margin: 0 auto 3em;
}

.separateur {
   display: block;
   width: 100%;
   border-bottom: solid 1px lightgray;

   &.separateur__vertical {
      height: 200px;
      width: 0;
      border-left: solid 1px lightgray;
   }
}

// espace du menu

.main__header-padding {
   padding-top: 120px;

   @include media(mobileonly) {
      padding-top: 72px;
   }
}

/**
   Background center cover
 */
.container-background {
   position: relative;
   overflow: hidden;

   img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      object-fit: cover;
      object-position: center;
   }
}

/**
   Header avec le fond et la catch phrase
 */
.header-banniere {
   @extend .container-background;

   &_content {
      font-size: 2.5em;
      text-transform: uppercase;
      font-weight: normal;
      text-align: center;
      background-color: rgba(#000, 0.5);
      padding: 100px 0;
      margin: 0;
      color: #fff;
   }

}

/**
   Loader Spinner
 */
.spinner-dual-ring {
   display: inline-block;
   width: 64px;
   height: 64px;

   &:after {
      content: " ";
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $color_secondaire;
      border-color: $color_secondaire transparent $color_secondaire transparent;
      animation: lds-dual-ring 1.2s linear infinite;
   }
}
@keyframes lds-dual-ring {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

/**
   Style du bouton
 */

.mdc-button:not(:disabled) {
   color: $color_secondaire
}

.mdc-button--outlined:not(:disabled) {
   border-color: $color_secondaire;
}

.mdc-button::before,
.mdc-button::after {
   background-color: $color_secondaire;
}

.mdc-button--raised {
   background-color: $color_secondaire !important;
   color: white !important;
}

/**
   Bandeau Alert Cookies
 */
.alert-cookies {
   z-index: 10;
   display: none; // display flex si le cookie n'est pas encore accepté (style.js)
   position: fixed;
   bottom: 0;
   width: 100%;
   background-color: white;
   color: $color_primaire;
   border-top: solid 2px $color_secondaire;
   padding: 15px 20px;
   box-sizing: border-box;

   p {
      flex: 4;
   }

   &_actions {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
   }


   @include media(mobileonly){
      flex-direction: column;
   }

}

/**
   ToolTip
 */
.tooltip {
   position: relative;

   // le message
   .tooltiptext {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: calc(100% + 10px);
      left: 50%;
      transform: translate(-50%);
      width: 120px;
      padding: 5px;
      border-radius: 6px;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.9em;
      color: #fff;
      text-align: center;
      background-color: #78909C;


      // le petit triangle pointer
      &:after {
         content: " ";
         position: absolute;
         bottom: 100%;
         left: 50%;
         margin-left: -5px;
         border-width: 5px;
         border-style: solid;
         border-color: transparent transparent #78909C transparent;
      }
   }

   &:hover .tooltiptext {
      animation: tooltip-animation ease-in-out 0.3s;
      visibility: visible;
      opacity: 1;
   }
}

@keyframes tooltip-animation {
   from {
      visibility: hidden;
      opacity: 0;
   }
   to {
      visibility: visible;
      opacity: 1;
   }
}