
footer {
   background-color: $color_primaire;
   padding: 20px 0;
   margin-top: 30px;

   .text-center {
      color: white;
      font-family: Montserrat, "Open Sans", sans-serif;
   }
}


.footer_nav-bar {

   .footer_logo {
      @extend .flex-row;
      @extend .flex-center-center;

      svg {
         height: 59px;

         * {
            fill: white !important;
         }
      }
   }

   .nav-bar_items {
      @extend .flex-row;
      @extend .flex-center-center;
      list-style: none;
      margin: 25px 0;
      padding: 0;

      li {
         min-width: 150px;
         text-align: center;
         font-family: Montserrat, "Open Sans", sans-serif;

         a {
            color: white;

            &:hover {
               text-decoration: underline;
            }
         }
         svg {
            max-width: 30px;
         }
         // facebook
         &:nth-child(1) a:hover {
            * {
               fill: cornflowerblue !important;
            }
         }
         // instagram
         &:nth-child(2) a:hover {
            * {
               fill: beige !important;
            }
         }
      }
   }

   // Paypal
   .nav-bar_payment-methods {
      @extend .flex-row;
      @extend .flex-center-center;

      margin-bottom: 10px;

      img {
         max-height: 50px;
         max-width: 120px;
      }

      @include media(mobileonly) {
         margin-bottom: 20px;
      }
   }
}

/**
   RESPONSIVE
 */
@include media(mobileonly) {
   .footer_nav-bar {

      .nav-bar_items:nth-child(2) {
         flex-direction: column;

         li {
            margin-bottom: 15px;
         }
      }
   }
}