
/**
   COULEURS
*/

$color_primaire: #573D1C;
$color_secondaire: #CC6600;
$color_background: white;

$width_max_content: 850px;
$width_max_content_shop: 950px;

$color_noir: #000;
$color_blanc: #fff;
$color_marron: #3d1901;
$color_havane: #995320;
$color_rouge: #cd0303;
$color_jaune: #ffc700;
$color_bleu-turquoise: #0000f8;
