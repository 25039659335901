@mixin font($font-family, $font-file) {
   @font-face {
      font-family: $font-family;
      src: url($font-file + '.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
   }
}
@include font('Montserrat', '../font-family/Montserrat/Montserrat-Regular');
@include font('Open Sans', '../font-family/Open_Sans/OpenSans-Regular');
@include font('PT Serif', '../font-family/PT_Serif/PTSerif-Regular');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

