$sommaire_item_height: 250px;

.categories {
    @extend .flex-row;
    flex-wrap: wrap;
    width: 100%;

    .category {
        @extend .container-background;
        @extend .flex-center-center;
        position: relative;
        width: calc(100%/2 - 20px);
        height: $sommaire_item_height;
        color: white;
        margin: 10px;
        border-radius: 5px;
        box-sizing: border-box;
        z-index: 1;

        @include media(mobileonly) {
            width: 100%;
        }

        &_details {
            @extend .flex-column;
            position: absolute;
            top: calc(100% - 44px);
            width: 100%;
            height: 100%;
            padding: 10px;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: rgba(#000, 0.75);
            cursor: pointer;
            overflow: hidden;
            transition: all 0.3s ease-out;

            h3 {
                color: white;
                margin: 0 10px 10px 10px;
                font-size: 1.2em;
            }
            p {
                color: transparent;
                width: 90%;
                height: 120px;
                margin: 0 10px;
                font-size: 0.92em;
            }
        }

        &:hover {
            & > a { // category_details
                top: 0%;

                h3 {
                    margin: 10px;
                }
                
                p {
                    animation-name: sommaire_item_animation;
                    animation-duration: 0.8s;
                    animation-delay: 0.2s;
                    animation-fill-mode: forwards;
                }
            }

        }

   }
}

/**
   Animations
 */
 @keyframes sommaire_item_animation {
    from {
       color: transparent;
    }
    to {
       color: white;
    }
 }